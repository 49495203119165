import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useContext, useEffect, useState } from "react";
import { ColorModeContext } from "../ToggleColorMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { Guests } from "./Guests";
import { Recognition } from "./Recognition";
import { AddRecognition } from "./AddRecognition";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

interface HomeProps {
  tab?: string;
}

export const Home = (props: HomeProps) => {
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [tab, setTab] = useState<string>(props.tab || "Guests");

  const navigate = useNavigate();

  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    switch (tab) {
      case "Guests":
        navigate("/guests");
        break;
      case "Recognition":
        navigate("/recognition");
        break;
      case "New Recognition":
        navigate("/add-recognition");
        break;
      default:
        navigate("/");
        break;
    }
  }, [tab, navigate]);

  const drawer = (
    <div>
      <Toolbar>
        <img src={require('../assets/logo.png')} style={{height: "50px", marginLeft: 'auto', marginRight: 'auto'}} />
      </Toolbar>
      <Divider />
      <List>
        {["Guests", "Recognition", "New Recognition"].map((text, index) => (
          <ListItemButton key={text} onClick={() => setTab(text)}>
            <ListItemText primary={text} />
          </ListItemButton>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          enableColorOnDark
          color="default"
          position="fixed"
          sx={{ width: { sm: `calc(100% - ${drawerWidth}px)` }, ml: { sm: `${drawerWidth}px` } }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1 }} noWrap component="div">
              {tab}
            </Typography>
            <IconButton onClick={colorMode.toggleColorMode} sx={{ ml: 1, mt: 1 }}>
              {theme.palette.mode === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
            sx={{ display: { xs: "block", sm: "none" }, "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth } }}>
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{ display: { xs: "none", sm: "block" }, "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth } }}
            open>
            {drawer}
          </Drawer>
        </Box>
        <Box component="main" sx={{ flexGrow: 1, bgcolor: "background.default", p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
          <Toolbar />
          {tab === "Guests" && <Guests />}
          {tab === "Recognition" && <Recognition />}
          {tab === "New Recognition" && <AddRecognition />}
        </Box>
      </Box>
    </div>
  );
};
