import { Alert, Button, Checkbox, Collapse, Fab, FormControlLabel, IconButton, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { useRef, useState } from "react";
import { backendURL } from "../env";
import axios from "axios";
import CloseIcon from '@mui/icons-material/Close';

interface AddRecognitionProps {
  date?: Date;
}

export const AddRecognition = (props: AddRecognitionProps) => {
  const [date, setDate] = useState<Date>(props.date || new Date());
  const [hasRecognition, setHasRecognition] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>("");
  const [noteError, setNoteError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const updateDate = (newValue: Date | null) => {
    if (newValue) {
      setDate(newValue);
    }
  };

  const addRecognition = () => {
    if (notes.length > 0) {
      setNoteError(false);
      const apiUrl = backendURL + "/recognition/create";
      const data = {
        date: format(date, "MMMM dd yyyy"),
        hasRecognition,
        notes,
      };
      axios.post(apiUrl, data).then((res) => {
        console.log("res:", res);
        if (res.status === 200) {
          setSuccess(true);
          // clear fields
          setHasRecognition(false);
          setNotes("");
          setDate(new Date());
        }
      });
    }
    else {
      setNoteError(true);
    }    
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        <DatePicker
          value={date}
          onChange={(newValue) => {
            updateDate(newValue);
          }}
          label={"Date"}
          inputRef={inputRef}
          format="MMMM dd yyyy"
        />
        <Button variant="text" onClick={() => updateDate(new Date())} sx={{ display: "flex", alignItems: "center" }}>
          Today
        </Button>
      </div>
      <div style={{ display: "flex" }}>
        <FormControlLabel
          value={hasRecognition}
          control={<Checkbox checked={hasRecognition} onChange={(event) => setHasRecognition(event.target.checked)} />}
          label="Has Recognition?"
          labelPlacement="start"
        />
      </div>
      <TextField
        label="Notes"
        multiline
        minRows={4}
        maxRows={30}
        fullWidth
        value={notes}
        onChange={(event) => setNotes(event.target.value)}
        error={noteError}
        helperText={noteError ? "Notes cannot be empty" : ""}
      />
      <br />
      <br />
      <label>
        <Fab variant="extended" color="primary" sx={{ width: "100%" }} onClick={addRecognition}>
          Save
        </Fab>
      </label>
      
      <Collapse in={success}>
        <br />
        <Alert 
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccess(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Recognition added successfully!
        </Alert>
      </Collapse>
    </div>
  );
};
