import { Route, Routes } from "react-router-dom";
import { Home } from "./components/Home";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

const App = () => {
  const theme = useTheme();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="App">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/add-recognition" element={<Home tab="New Recognition" />} />
            <Route path="/guests" element={<Home tab="Guests" />} />
            <Route path="/recognition" element={<Home tab="Recognition" />} />
          </Routes>
        </ThemeProvider>
      </div>
    </LocalizationProvider>
  );
};

export default App;
