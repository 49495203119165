import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { backendURL } from "../env";
import MaterialReactTable, { MaterialReactTableProps, type MRT_ColumnDef, type MRT_Cell, MRT_Row } from "material-react-table";
import { Box, Button, Checkbox, TextField } from "@mui/material";
import Moment from "moment";
import { format } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Delete as DeleteIcon } from "@mui/icons-material";

type Recognition = {
  _id: string;
  date: string;
  hasRecognition: boolean;
  notes: string;
  relatedGuestIds: string[];
};
export const Recognition = () => {
  const [recognitions, setRecognitions] = useState<Recognition[]>([]);
  const [validationErrors, setValidationErrors] = useState<{ [cellId: string]: string }>({});
  Moment.locale("en");

  const getCommonEditTextFieldProps = useCallback(
    (cell: MRT_Cell<Recognition>): MRT_ColumnDef<Recognition>["muiTableBodyCellEditTextFieldProps"] => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid = event.target.value !== null && event.target.value !== "";
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors]
  );

  const handleDeleteRows = async (rows: MRT_Row<Recognition>[]) => {
    rows.forEach((row) => {
      const apiUrl = backendURL + "/recognition/delete?recognitionID=" + row.original._id;
      axios.delete(apiUrl).then((res) => {
        if (res.status === 200) {
          setRecognitions((prev) => {
            const newRecognitions = [...prev];
            newRecognitions.splice(row.index, 1);
            return newRecognitions;
          });
        }
      });
    });
  };

  const columns = useMemo<MRT_ColumnDef<Recognition>[]>(
    () => [
      {
        accessorKey: "date",
        header: "Date",
        Edit: ({ cell, row }) => (
          <DatePicker
            value={new Date(cell.getValue() as string)}
            onChange={(newValue: Date | null) => {
              if (newValue) {
                setRecognitions((prev) => {
                  const newRecognitions = [...prev];
                  newRecognitions[row.index].date = format(newValue, "MMMM dd, yyyy");
                  return newRecognitions;
                });
              }
            }}
          />
        ),
      },
      {
        accessorKey: "hasRecognition",
        header: "Has Recognition?",
        render: (rowdata: { valueOf: () => boolean }) => (
          <Checkbox
            checked={rowdata?.valueOf() as boolean}
            onChange={(event) => {
              event.preventDefault();
              return false;
            }}
          />
        ),
        Edit: ({ cell, row }) => (
          <Checkbox
            checked={cell.getValue()?.valueOf() as boolean}
            onChange={(event) => {
              event.preventDefault();
              setRecognitions((prev) => {
                const newRecognitions = [...prev];
                newRecognitions[row.index].hasRecognition = event.target.checked;
                return newRecognitions;
              });
            }}
          />
        ),
        Cell: ({ renderedCellValue }) => (
          <Checkbox
            checked={renderedCellValue?.valueOf() as boolean}
            onChange={(event) => {
              event.preventDefault();
              return false;
            }}
          />
        ),
      },
      {
        accessorKey: "notes",
        header: "Notes",
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "text",
        }),
        Cell: ({renderedCellValue}) => (
          <TextField
            fullWidth
            multiline
            maxRows={4}
            size="small"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            value={renderedCellValue as string}
            onChange={(event) => {
              event.preventDefault();
              return false;
            }}
          />
        ),
        Edit: ({ cell, row }) => (
          <TextField
            fullWidth
            multiline
            maxRows={5}
            value={cell.getValue() as string}
            onChange={(event: { target: { value: string; }; }) => {
              setRecognitions((prev) => {
                const newRecognitions = [...prev];
                newRecognitions[row.index].notes = event.target.value;
                return newRecognitions;
              });
            }}
          />
        ),
      },
    ],
    [getCommonEditTextFieldProps]
  );

  const handleSaveRowEdits: MaterialReactTableProps<Recognition>["onEditingRowSave"] = async ({ exitEditingMode, row, values }) => {
    if (values.notes !== "") {
      console.log("values:", values);
      console.log("row:", row);
      const newValues = {
        _id: row.original._id,
        date: row.original.date,
        hasRecognition: row.original.hasRecognition,
        notes: row.original.notes,
        relatedGuestIds: [],
      };

      const res = await handleUpdateRow(newValues);
      const newData = res.data.recognition;
      const formattedData = {
        _id: newData._id,
        date: format(new Date(newData.date), "MMMM dd, yyyy"),
        hasRecognition: newData.hasRecognition,
        notes: newData.notes,
        relatedGuestIds: newData.relatedGuestIds,
      };
      setRecognitions((prev) => {
        const newRecognitions = [...prev];
        newRecognitions[row.index] = formattedData;
        return newRecognitions;
      });
      exitEditingMode();
    }
  };

  const handleUpdateRow = async (newValues: Recognition) => {
    const apiUrl = backendURL + "/recognition/update?recognitionID=" + newValues._id;
    const data = {
      date: newValues.date,
      hasRecognition: newValues.hasRecognition,
      notes: newValues.notes,
    };
    return await axios.put(apiUrl, data);
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  useEffect(() => {
    const callRecognitionEndpoint = async () => {
      const apiUrl = backendURL + "/recognition/all";
      const res = await axios.get(apiUrl);
      const data = res.data.recognitions;
      const formattedData = data.map((recognition: Recognition) => {
        return {
          _id: recognition._id,
          date: format(new Date(recognition.date), "MMMM dd, yyyy"),
          hasRecognition: recognition.hasRecognition,
          notes: recognition.notes,
        };
      });
      setRecognitions(formattedData);
    };
    callRecognitionEndpoint();
  }, []);

  return (
    <div>
      <MaterialReactTable
        data={recognitions}
        columns={columns}
        editingMode="row"
        enableColumnOrdering
        enableEditing
        enableRowSelection
        muiTableBodyCellEditTextFieldProps={{ variant: "outlined" }}
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderTopToolbarCustomActions={({ table }) => (
          <Box sx={{ display: "flex", gap: "1rem", p: "0.5rem", flexWrap: "wrap" }}>
            <Button color="primary" variant="contained" href="/add-recognition">
              Add Recognition for Today
            </Button>
            {(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) && (
              <Button
                color="primary"
                variant="contained"
                startIcon={<DeleteIcon />}
                onClick={async () => {
                  await handleDeleteRows(table.getSelectedRowModel().rows);
                  table.resetRowSelection();
                }}>
                Delete Selected
              </Button>
            )}
          </Box>
        )}
      />
    </div>
  );
};
